import "ant-design-vue/es/modal/style";
import _Modal from "ant-design-vue/es/modal";
import _defineProperty from "E:/gebibao/aims_vue/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

import 'animate.css';
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import HeaderNav from "@/components/HeaderNav.vue";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import { getUrlList, announcementForUser, getFooterBanner } from "@/apis";
import { getAnnouncementC, rmAnnouncementC, setAnnouncementC } from "@/util/cookies";
moment.locale("zh-cn");
let App = class App extends Vue {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "store", useStore());

    _defineProperty(this, "zh_CN", zh_CN);

    _defineProperty(this, "visible", false);

    _defineProperty(this, "title", "");

    _defineProperty(this, "content", "");
  }

  created() {
    this.store.commit("windowWidthChange", document.documentElement.clientWidth);

    try {
      const lang = localStorage.getItem('wglang');

      if (lang && lang != 'zh') {
        this.store.commit('langChange', lang);
      }
    } catch {//
    }

    getUrlList().then(res => {
      if (res.code !== 200) {
        return;
      }

      if (res.data !== "") {
        const urlList = JSON.parse(res.data);
        this.store.commit("urlListChange", {
          realAccount: urlList.realAccount ? urlList.realAccount : "",
          virtualAccount: urlList.virtualAccount ? urlList.virtualAccount : "",
          login: urlList.login ? urlList.login : "",
          androidMt4: urlList.androidMt4 ? urlList.androidMt4 : "",
          iosMt4: urlList.iosMt4 ? urlList.iosMt4 : "",
          pcMt4: urlList.pcMt4 ? urlList.pcMt4 : ""
        });
      }
    }).catch(err => {
      console.log(err);
    });
    getFooterBanner().then(res => {
      if (res.code === 200 && res.data.banner_image) {
        this.store.commit('footerBannerChange', res.data.banner_image);
      }
    });
  }

  mounted() {
    announcementForUser().then(res => {
      if (res.data) {
        if (res.data.num > 0) {
          const saveCookie = getAnnouncementC();

          if (saveCookie !== "") {
            if (saveCookie.id && saveCookie.num && saveCookie.id === res.data.id) {
              const num = Number(saveCookie.num);

              if (num >= res.data.num) {
                return;
              }

              setAnnouncementC({
                id: res.data.id,
                num: num + 1
              });
            } else {
              setAnnouncementC({
                id: res.data.id,
                num: 1
              });
            }
          } else {
            setAnnouncementC({
              id: res.data.id,
              num: 1
            });
          }
        }

        this.title = res.data.title ? res.data.title : "";
        this.content = res.data.content ? res.data.content : "";
        this.visible = true;
      } else {
        rmAnnouncementC();
      }
    }).catch(err => {
      console.log(err);
    });

    window.onresize = () => {
      this.store.commit("windowWidthChange", document.documentElement.clientWidth);
    };

    const chatScript = document.createElement('script');
    chatScript.type = 'text/javascript'; //关闭该在线客服功能，使用米多客在线客服
    //chatScript.src = 'https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=e6c3fa50-8c01-11e9-b354-f5d388230b7f&autoShow=false&language=ZHCN';

    chatScript.src = '/static/miduoke.js';
    chatScript.async = true;
    document.body.appendChild(chatScript);
  }

  goToFirst() {
    window.scrollTo(0, 0);
  }

  goToMt4() {
    this.$router.push({
      name: "mt4",
      query: {
        action: 1
      }
    });
  }

};
App = __decorate([Options({
  components: {
    HeaderNav,
    Modal: _Modal
  }
})], App);
export default App;